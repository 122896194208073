import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MatButtonModule } from "@angular/material/button";
import { EmailBannerComponent } from "./components/email-banner/email-banner.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { NoteBannerComponent } from "./components/note-banner/note-banner.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SubscribeFormComponent } from "./components/subscribe-form/subscribe-form.component";
import { HttpClientModule } from "@angular/common/http";
import { ChometzComponent } from "./chometz/chometz.component";
import { HomeComponent } from "./home/home.component";
import { SuccessFailedComponent } from "./dialogs/success-failed/success-failed.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { DonationComponent } from "./dialogs/donation/donation.component";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { SliderComponent } from "./components/slider/slider.component";
import { ChametzFormComponent } from "./chometz-form/chametz-form.component";
import { RidesFormComponent } from "./rides-form/rides-form.component";
import { KbisComponent } from "./kbis/kbis.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NoteBannerComponent,
    EmailBannerComponent,
    SubscribeFormComponent,
    ChometzComponent,
    HomeComponent,
    SuccessFailedComponent,
    DonationComponent,
    SliderComponent,
    ChametzFormComponent,
    RidesFormComponent,
    KbisComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSnackBarModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatCarouselModule.forRoot(),
  ],
  entryComponents: [SubscribeFormComponent, DonationComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
