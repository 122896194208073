import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.scss']
})
export class SubscribeFormComponent implements OnInit {

  subscriptionForm: FormGroup;
  controls: any = {};

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SubscribeFormComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.buildForm();
    this.setControlKeys();
    this.setControlAndBounceMessage(this.controls);
  }

  buildForm() {
    this.subscriptionForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      company: ['']
    });
  }

  setControlKeys() {
    Object.keys(this.subscriptionForm.controls).map(key => {
      this.controls[key] = '';
    });
  }

  setMessage(control: AbstractControl, name: string): void {
    this.controls[name] = '';
    if ((control.touched || control.dirty) && control.errors) {
      this.controls[name] = '*Required';
    }
  }

  setControlAndBounceMessage(controls) {
    Object.keys(controls).map(controlString => {
      const control = this.subscriptionForm.get(controlString);
      control.valueChanges.pipe(debounceTime(100)).subscribe(() => {
        this.setMessage(control, controlString);
      });
    });
  }

  setAllControlsAsTouched(controls) {
    Object.keys(controls).map(controlString => {
      const control = this.subscriptionForm.get(controlString);
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
      this.setMessage(control, controlString);
    });
  }

  onSubmit() {
    if (!this.subscriptionForm.valid) {
      this.setAllControlsAsTouched(this.controls);
    } else {
      const body = new HttpParams()
      .set('form-name', 'subscriptions')
      .append('email', this.subscriptionForm.value.email)
      .append('firstName', this.subscriptionForm.value.firstName)
      .append('lastName', this.subscriptionForm.value.lastName)
      .append('company', this.subscriptionForm.value.company);
      this.http.post('/', body.toString(), {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}).subscribe(
        res => { this.dialogRef.close('Your info was submitted for updates.'); },
        err => {
          if (err instanceof ErrorEvent) {
            this.dialogRef.close('There was an error, your info was not saved.');
          } else {
            if (err.status === 200) {
              this.dialogRef.close('Your info was submitted for updates.');
            } else {
              this.dialogRef.close('There was an error, your info was not saved.');
            }
          }
        }
      );
    }
  }

}
