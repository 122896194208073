import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"]
})
export class SliderComponent implements OnInit {
  slides = [
    {"image": "assets/images/hero-1.png"},
    {"image": "assets/images/hero-3_updated.png"}
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
