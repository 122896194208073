import { MatDialog } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";
import { DonationComponent } from "src/app/dialogs/donation/donation.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(private dialog: MatDialog, private router: ActivatedRoute) {}

  ngOnInit() {
    if (window.location.href.includes("donate")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const amount = Number(urlSearchParams.get("amount"));
      const reason = urlSearchParams.get("reason");
      this.donationDialog(amount, reason);
    }
  }

  donationDialog(amount, reason) {
    this.dialog.open(DonationComponent, {
      width: "100%",
      maxWidth: "600px",
      maxHeight: "90vh",
      autoFocus: false,
      data: {amount, reason}
    });
  }
}
