import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChametzFormComponent } from './chometz-form/chametz-form.component';
import { ChometzComponent } from './chometz/chometz.component';
import { HomeComponent } from './home/home.component';
import { KbisComponent } from './kbis/kbis.component';
import { RidesFormComponent } from './rides-form/rides-form.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'donate', component: HomeComponent },
  // {path: 'chumetz', component: ChometzComponent},
  { path: 'chumetz', component: ChametzFormComponent },
  { path: 'rides', component: RidesFormComponent },
  { path: 'kbisfoodregistration2024', component: KbisComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
