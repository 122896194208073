<div class="images">
  <mat-carousel
  timings="250ms ease-in"
  [autoplay]="true"
  interval="5000"
  color="primary"
  maxWidth="auto"
  proportion="30"
  slides="5"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="false"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of slides; let i = index"
    [image]="slide.image"
    overlayColor="#00000040"
    [hideOverlay]="false"
  ></mat-carousel-slide>
</mat-carousel>
</div>
