<form (ngSubmit)="onSubmit()" [formGroup]="subscriptionForm" class="subscriptionForm" method="POST"
  name="subscriptions">
  <div class="subscriptionForm_title">Subscribe For Updates</div>
  <input type="hidden" name="form-name" value="subscriptions" />
  <div class="input-wrap">
    <label for="email">
      Email Address
      <small *ngIf="controls.email">
        {{ controls.email }}
      </small>
    </label>
    <input [class.is-invalid]="controls.email" type="text" id="email" name="email" formControlName="email" required />
  </div>
  <div class="input-wrap">
    <label for="firstName">
      First Name
      <small *ngIf="controls.email">
        {{ controls.firstName }}
      </small>
    </label>
    <input [class.is-invalid]="controls.firstName" type="text" id="firstName" name="firstName"
      formControlName="firstName" required />
  </div>
  <div class="input-wrap">
    <label for="lastName">
      Last Name
      <small *ngIf="controls.email">
        {{ controls.lastName }}
      </small>
    </label>
    <input [class.is-invalid]="controls.lastName" type="text" id="lastName" name="lastName" formControlName="lastName"
      required />
  </div>
  <div class="input-wrap">
    <label for="company">
      Company
    </label>
    <input [class.is-invalid]="controls.company" type="text" id="company" name="company" formControlName="company" placeholder="(optional)" />
  </div>
  <button class="subscriptionForm_subscribe" type="submit">Subscribe</button>
</form>