import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-donation",
  templateUrl: "./donation.component.html",
  styleUrls: ["./donation.component.scss"],
})
export class DonationComponent implements OnInit {
  session: any;
  amountToDonate;
  donationReason;
  submitting: any;
  donationOtherReason = "";
  paymentType = "cc";
  payPalLino = "https://www.paypal.me/newarkshul";
  donationReasons = [
    "Aliyah Payments",
    "Membership Payments",
    "General Donations",
    "Special Donations",
    "Sponsor Website",
    "Other",
  ];
  constructor(
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.amount) {
      this.amountToDonate = this.data.amount;
    }
    if (this.data.reason) {
      if (this.donationReasons.includes(this.data.reason)) {
        this.donationReason = this.data.reason;
      } else {
        this.donationReason = "Other";
        this.donationOtherReason = this.data.reason;
      }
    }
  }

  getStripeInfo(amount) {
    let reason = this.donationReason;
    if (reason === "Other") {
      reason = this.donationOtherReason;
    }
    const url = `/.netlify/functions/stripe-process?amount=${amount}&reason=${reason}`;
    return this.http.get(url);
  }

  ngOnInit() {}

  goToPayPal() {
    return (window.location.href = `https://www.paypal.com/paypalme/NewarkShul/${this.amountToDonate}`);
  }

  donate() {
    if (this.paymentType === "cc") {
      this.continueToStripe();
    } else {
      this.goToPayPal();
    }
  }

  formNotCompleted() {
    const reasonNotSelected =
      !this.donationReason ||
      (this.donationReason === "Other" && !this.donationOtherReason);
    return (
      !this.amountToDonate || this.amountToDonate == 0 || reasonNotSelected
    );
  }
  continueToStripe() {
    this.submitting = true;

    const stripePKey =
      "pk_live_51IT5gqC40Pi3NHJDrNBjGYRHECDEzmRrknYZZwhh4Y9dGXJhFAA0Q9XBE3vv5i9OQo9DUwrotSFoOaOWVeuZr2L000ophbxf3W";
    const stripe = Stripe(stripePKey);
    const amountToCharge = this.amountToDonate;
    let amount = amountToCharge.toString().replace(",", "").replace("$", "");
    const decimal = "00";
    if (amount.toString().split(".")[1]) {
      amount = amount.toString().split(".")[0];
    }
    this.getStripeInfo(amount + decimal).subscribe((session: any) => {
      // console.log(session)
      this.session = session;
      stripe.redirectToCheckout({
        sessionId: this.session.id,
      });
    });
  }
}
