<div class="main-image">
  <div class="form_info">
    <h3 class="form_header">Make a Donation</h3>
    <p class="form_desc">
      Every donation makes a difference no matter how small. We appreciate all
      donations submitted, and it helps our community 100%
    </p>

    <div class="input-container">
        <mat-label>Donation Reason</mat-label>
        <mat-radio-group [(ngModel)]="donationReason" aria-label="Select an option" class="radio-group">
          <mat-radio-button  *ngFor="let reason of donationReasons" [value]="reason" class="radio-button">{{ reason }}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div *ngIf="donationReason === 'Other'" class="input-container">
      <mat-form-field style="width: 100%;" class="example-full-width" appearance="fill">
        <mat-label *ngIf="!donationOtherReason">Please Explain</mat-label>
        <textarea [(ngModel)]="donationOtherReason" matInput></textarea>
      </mat-form-field>
    </div>

    <div class="amounts">
      <span
        (click)="amountToDonate = amount"
        class="amount"
        [class.amount--selected]="amountToDonate == amount"
        *ngFor="let amount of [5, 10, 18, 50, 100, 150, 1000]; index as i"
      >
        <span> {{ amount | currency: "USD":"symbol":"1.0-0" }}</span>
      </span>
    </div>
    <div class="other">
      <label for="other">Amount</label>
      <input
        type="text"
        [(ngModel)]="amountToDonate"
        id="other"
        class="other"
      />
    </div>


    <mat-button-toggle-group style="margin-top: 26px" [(ngModel)]="paymentType">
      <mat-button-toggle value="cc">
        <span class="pay-option">
          <i class="fal fa-credit-card"></i>
          <span> Credit Card </span>
        </span>
      </mat-button-toggle>
      <mat-button-toggle value="PayPal">
        <span class="pay-option">
          <i class="fab fa-paypal"></i>
          <span> PayPal </span>
        </span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="action">
    <button
      [disabled]="formNotCompleted()"
      (click)="donate()"
      class="action_text"
    >
      Continue <i class="fal fa-arrow-right" aria-hidden="true"></i>
    </button>
  </div>
</div>
