import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-note-banner',
  templateUrl: './note-banner.component.html',
  styleUrls: ['./note-banner.component.scss']
})
export class NoteBannerComponent implements OnInit {
  message = 'Please see below for updated Slichos times';
  constructor() { }

  ngOnInit() {
  }

}
