<div class="hero">
  <!-- <app-email-banner></app-email-banner> -->
  <div class="hero_content">
    <!-- <div class="cta">
      <button (click)="navToForm()" mat-raised-button color="primary">
        <span>Sell Your Chametz</span>
        <i class="fas fa-long-arrow-alt-right"></i>
      </button>
    </div> -->

    <div class="hero_title">
      <span class="hero_title_word">Pray, </span>
      <span class="hero_title_word">Learn, </span><br />
      <span class="hero_title_word">Connect</span>
    </div>
    <hr />
  </div>
</div>
<!-- <app-note-banner class="note"></app-note-banner> -->
<div class="prayer" id="zmanim">
  <div class="accent-header">
    <small lang="iw"> נוסח ספרד</small> <br />
    Prayer Times*<br />
    <div class="accent-header_hebrew" lang="iw">זמני תפילה</div>
  </div>

  <!-- <div class="new-zman">
    <button (click)="navToZman()" mat-raised-button color="primary">
      <span>Slichos schedule</span>
      <i class="fas fa-long-arrow-alt-right"></i>
    </button>
  </div> -->

  <div class="prayer_content">
    <div class="prayer-boxes prayer-boxes--morning">
      <div class="prayer-boxes_title">
        <div class="prayer-boxes_icon"></div>
        <div class="prayer-boxes_title-text">Morning</div>
        <div
          class="prayer-boxes_title-text prayer-boxes_title-text--hebrew"
          lang="iw"
        >
          שחרית
        </div>
      </div>

      <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">First</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">ראשון</div>
        <div class="prayer-boxes_minyan-time">7:00 AM</div>
        <!-- <div class="prayer-boxes_slichos-day-n" lang="iw">סליחות פרשת נצבים</div>
          <div class="prayer-boxes_slichos-day-e" lang="iw">סליחות עי"מ תשובה</div>
          <div class="prayer-boxes_slichos-time-n">6:40 AM</div>
          <div class="prayer-boxes_slichos-time-e">6:30 AM</div> -->
      </div>

      <div class="prayer-boxes_minyan">
        <!-- <div class="prayer-boxes_minyan-name">Second</div>
          <div class="prayer-boxes_minyan-hebrew" lang="iw">שני</div>
          <div class="prayer-boxes_minyan-time">7:30 AM</div> -->
        <!-- <div class="prayer-boxes_slichos-day-n" lang="iw">סליחות פרשת נצבים</div>
          <div class="prayer-boxes_slichos-day-e" lang="iw">סליחות עי"מ תשובה</div>
          <div class="prayer-boxes_slichos-time-n">7:10 AM</div>
          <div class="prayer-boxes_slichos-time-e">7:00 AM</div> -->
      </div>

      <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">Second</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">שני</div>
        <div class="prayer-boxes_minyan-time">9:30 AM</div>
      </div>
    </div>

    <div class="prayer-boxes prayer-boxes--noon">
      <div class="prayer-boxes_title">
        <div class="prayer-boxes_icon prayer-boxes_icon--noon"></div>
        <div class="prayer-boxes_title-text">Afternoon</div>
        <div
          class="prayer-boxes_title-text prayer-boxes_title-text--hebrew"
          lang="iw"
        >
          מנחה
        </div>
      </div>
      <!-- <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">First</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">ראשון </div>
        <div class="prayer-boxes_minyan-time">1:15 PM</div>
      </div>
      <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">Second</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">שני</div>
        <div class="prayer-boxes_minyan-time">3:00 PM</div>
      </div> -->

      <!-- <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">Third</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">שלישי</div>
        <div class="prayer-boxes_minyan-time">5:00 PM</div>
      </div> -->
      <!-- <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">First</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">ראשון</div>
        <div class="prayer-boxes_minyan-time">1:15 PM</div>
      </div> -->
      <div class="prayer-boxes_minyan">
        <!-- <div class="prayer-boxes_minyan-name">Second</div>
          <div class="prayer-boxes_minyan-hebrew" lang="iw">שני</div>
          <div class="prayer-boxes_minyan-time">7:30 AM</div> -->
        <!-- <div class="prayer-boxes_slichos-day-n" lang="iw">סליחות פרשת נצבים</div>
          <div class="prayer-boxes_slichos-day-e" lang="iw">סליחות עי"מ תשובה</div>
          <div class="prayer-boxes_slichos-time-n">7:10 AM</div>
          <div class="prayer-boxes_slichos-time-e">7:00 AM</div> -->
      </div>
      <div class="prayer-boxes_minyan">
        <div class="prayer-boxes_minyan-name">Minyan</div>
        <div class="prayer-boxes_minyan-hebrew" lang="iw">מנין</div>
        <div class="prayer-boxes_minyan-time">4:35 PM</div>
      </div>
    </div>
  </div>

  <div class="prayer_note">*Ovdei Hashem is open during workdays only.</div>
</div>

<div class="about">
  <div class="location" id="location">
    <div class="accent-header">Location</div>
    <div class="info-item">
      <a
        class="info-item_link"
        href="https://goo.gl/maps/f4s2KydaH7C2"
        target="_black"
      >
        <div class="info-item_text">69 blanchard st newark nj</div>
        <div class="info-item_icon"><i class="fal fa-arrow-right"></i></div>
      </a>
    </div>
    <div class="location_small-text">
      Located in Fabuwood campus, situated 10 minutes from Newark airport
    </div>
  </div>
  <div class="contact" id="contact">
    <div class="accent-header">Contact</div>
    <div class="info-item">
      <a class="info-item_link" href="mailto:info@ovdeihashem.org">
        <div class="info-item_icon"><i class="fal fa-envelope"></i></div>
        <div class="info-item_text">info@ovdeihashem.org</div>
      </a>
    </div>
    <div class="info-item">
      <a class="info-item_link" href="tel:2013801511">
        <div class="info-item_icon"><i class="fal fa-phone"></i></div>
        <div class="info-item_text">201.380.1511</div>
      </a>
    </div>
  </div>
  <a
    class="infocus"
    style="
      background-color: #275859;
      padding: 18px 125px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-area: 2 / 1 / 2 / 4;
      margin-top: 56px;
      justify-self: center;
      display: flex;
      align-items: center;
      row-gap: 32px;
      text-decoration: none;
    "
    href="https://www.fabuwood.com/infocus/"
    target="_blank"
  >
    <div
      class="infocus-logos"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 64px;
      "
    >
      <img
        src="/assets/images/infocus logo.png"
        alt=""
        width="163"
        style="
          max-width: 163px;
          border: 0;
          height: auto;
          outline: none;
          text-decoration: none;
          vertical-align: bottom;
        "
      />

      <img
        src="/assets/images/Ovdei logo.png"
        alt=""
        width="88"
        style="
          height: auto;
          outline: none;
          text-decoration: none;
          vertical-align: bottom;
        "
      />
    </div>
    <p style="color: white; min-width: 285px;">
      Ovdei Hashem is part of the InFocus Initiative
    </p>
  </a>
</div>

<app-slider></app-slider>
