import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SuccessFailedComponent } from "../dialogs/success-failed/success-failed.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.success) {
        const dataSuccess = {
          status: "Success",
          desc: "Thank you very much for your tax deductible donation!",
          icon: "fa-check-circle",
        };
        this.openDialog(dataSuccess);
      } else if (params.error) {
        const dataFail = {
          status: "Oops",
          desc:
            `We were not able to process your donation. Please check the information
             you entered and try again, or reach out to the rov for further assistance`,
          icon: "fa-times-circle",
        };
        const dialog = this.openDialog(dataFail);
        dialog
          .afterClosed()
          .toPromise()
          .then((x) => (window.location.search = ""));
      }
    });
  }

  navToZman() {
    window.open("/assets/pdf/זמני-תפילה-לחודש-אלול-ותשרי-Tishrei-תשפג.pdf", "_blank");
  }
  openDialog(data) {
    const dialog = this.dialog.open(SuccessFailedComponent, {
      data,
      width: "500px",
    });
    return dialog;
  }

  navToForm() {
    this.router.navigate(["/chumetz"]);
  }
}
