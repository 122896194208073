import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-failed',
  templateUrl: './success-failed.component.html',
  styleUrls: ['./success-failed.component.scss']
})
export class SuccessFailedComponent implements OnInit {

  status;
  desc;
  icon: any;
  constructor(private dialogRef: MatDialogRef<SuccessFailedComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.status = data.status;
    this.desc = data.desc;
    this.icon = data.icon;
   }

  ngOnInit() {

  }

  continue(){
    this.dialogRef.close();
  }

}
