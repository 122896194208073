<div class="chometz-form">
  <div class="chometz-form_title">Sell Your Chametz</div>
  <div class="chometz-form_note">
    Since there are many legal intricacies involved in this sale, a rabbi acts
    as our agent both to sell the Chametz to the non-Jew on the morning before
    Passover and also to buy it back the evening after Passover ends.
    <b
      >Note: The deadline for submissions is 11:00pm (local time) on April 13,
      2022 12th day of Chodesh Nissan 5782</b
    >
  </div>

  <div class="chometz-form_note chometz-form_note--rtl">
    {{ this.topShtar }}
  </div>

  <form
    class="chometz-form_form"
    subject="Chometz"
    name="chometz"
    [formGroup]="form"
    (ngSubmit)="submitForm()"
  >
    <input type="text" formControlName="form-name" hidden value="chometz" />

    <div class="input-container">
      <div class="input-container_label">First Name *</div>
      <input
        type="text"
        class="input-container_input"
        formControlName="firstName"
        required
      />
      <div *ngIf="invalidInput('firstName')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="input-container">
      <div class="input-container_label">Last Name *</div>
      <input
        type="text"
        class="input-container_input"
        formControlName="lastName"
        required
      />
      <div *ngIf="invalidInput('lastName')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="input-container">
      <div class="input-container_label">Phone *</div>
      <input
        type="text"
        class="input-container_input"
        formControlName="phone"
        required
      />
      <div *ngIf="invalidInput('phone')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>
    <div class="input-container">
      <div class="input-container_label">Email Address *</div>
      <input
        type="email"
        class="input-container_input"
        formControlName="email"
        required
      />
      <div *ngIf="invalidInput('email')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>
    <div class="input-container">
      <div class="input-container_label">
        Full Address (Street, State, City, Zip) *
      </div>
      <input
        type="email"
        class="input-container_input"
        formControlName="address"
        required
      />
      <div *ngIf="invalidInput('address')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="input-container">
      <div class="input-container_label">Comments</div>
      <input
        type="text"
        class="input-container_input"
        formControlName="comments"
      />
    </div>

    <div class="input-container">
      <div class="input-container_label">What are you selling? *</div>
      <label
        style="display: inline-block; width: 100%"
        *ngFor="let place of placesSelling"
        class="input-container_check-label"
      >
        <input
          type="checkbox"
          class="input-container_checkbox"
          [value]="place.checked"
          (change)="onPlaceSellChange(place)"
          name="placesSelling"
        />
        {{ place.name }}
      </label>
      <div
        style="margin: 22px 12px"
        *ngIf="isOtherPlace()"
        class="input-container"
      >
        <div class="input-container_label">
          Please Explain (also add more addresses)
        </div>
        <input
          name="otherPlace"
          formControlName="otherPlace"
          type="text"
          class="input-container_input"
        />
      </div>

      <div *ngIf="noPlaceSelected()">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="input-container">
      <div class="input-container_label">
        Donation Amount*
        <small class="tax-note">(all donations are tax deductible)</small>
      </div>
      <label
        *ngFor="let donation of donationsAmounts"
        class="input-container_check-label"
      >
        <input
          type="radio"
          class="input-container_checkbox"
          (change)="onDonationChange(donation)"
          name="donation"
          [value]="donation"
        />
        {{ getDonationText(donation) }}
      </label>

      <div
        style="margin: 22px 12px"
        *ngIf="selectedDoantion === 'other'"
        class="input-container"
      >
        <div class="input-container_label">Amount</div>
        <input
          type="number"
          min="1"
          step="1"
          name="otherAmount"
          formControlName="otherAmount"
          class="input-container_input"
        />
      </div>

      <div *ngIf="noDonationSelected()">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="input-container">
      <!-- <div class="input-container_label">
        Donation Method
      </div> -->
      <mat-button-toggle-group formControlName="paymentType" >
        <mat-button-toggle value="PayPal">PayPal</mat-button-toggle>
        <mat-button-toggle value="cc">Credit Card</mat-button-toggle>
      </mat-button-toggle-group>
      <div *ngIf="invalidInput('paymentType')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>

    <div class="chometz-form_note">{{ this.signContent }}</div>
    <div style="margin: 22px 10px" class="input-container">
      <div class="input-container_label">Signature</div>
      <input
        placeholder="Initials"
        style="max-width: 150px"
        type="text"
        name="signature"
        formControlName="signature"
        class="input-container_input"
      />
      <div *ngIf="invalidInput('signature')">
        <small class="input-container_text-danger">*Required</small>
      </div>
    </div>
    <button id="submit-button" class="button" type="submit">Submit Form</button>
  </form>
</div>
<!-- [disabled]="form.invalid" -->
