import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SuccessFailedComponent } from "../dialogs/success-failed/success-failed.component";

interface Place {
  name: string;
  checked: boolean;
}
@Component({
  selector: "app-chometz",
  templateUrl: "./chometz.component.html",
  styleUrls: ["./chometz.component.scss"],
})
export class ChometzComponent implements OnInit {
  form: FormGroup;
  topShtar = `
  ב"ה ניסן תשפ"ב לפ"ק
  במילוי ושליחת טופס זה, אני נותנ/ת כוח ורשות להרב  מאיר יודא ראטטענבערג, וממנה אותו לשליח ומורשה במקומי למכור ולהשכיר, בעצמו, או על ידי שלוחיו, כל מיני חמץ ותערובות-חמץ הנמצא ברשותנו, ובכל מקום שהוא, קודם זמן איסורם לאיזה אינו יהודי שירצה, וכן להשכיר את כל המקומות והחדרים שנמצא בהם איזה פרט מהאמור למעלה, הן בעצמו והן ע"י שלוחו לעשות את כל מה שירצה, וכל זה קיבלתי על עצמי.
  `;
  signContent = `I the undersigned, fully empower and permit Rabbi Meyer Y. Rottenberg to act in my place and stead, and on my behalf to sell all Chametz possessed by me, knowingly or unknowingly as defined by the Torah and Rabbinic Law (e.g. Chametz, possible Chametz, and all kind of Chametz mixtures).`;
  options = {
    headers: new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    ),
  };
  placesSelling: Place[] = [
    { name: "Entire Aprtment (כל הבית)", checked: false },
    { name: "Marked Places (מקומות מסומנים)", checked: false },
    { name: "Storge Room (בית האוצר)", checked: false },
    { name: "others", checked: false },
  ];
  donationsAmounts = [18, 36, 54, 72, 101, "other"];
  selectedDoantion: any;
  triedSubmitting: any;
  submitting: boolean;
  session: any;
  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      "form-name": [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      phone: ["", Validators.required],
      address: ["", Validators.required],
      email: ["", Validators.required],
      otherPlace: [""],
      otherAmount: [""],
      comments: [""],
      signature: ["", Validators.required],
      paymentType: ["", Validators.required],
    });

    this.route.queryParams.subscribe((params) => {
      if (params.success) {
        const dataSuccess = {
          status: "Success",
          desc:
            "Your form has been received. Thank you very much for your tax deductible donation!",
          icon: "fa-check-circle",
        };
        this.openDialog(dataSuccess);
      } else if (params.error) {
        const dataFail = {
          status: "Oops",
          desc:
            "We were not able to process your donation. Please check the information you entered and try again, or reach out to the rov for further assistance",
          icon: "fa-times-circle",
        };
        const dialog = this.openDialog(dataFail);
        dialog
          .afterClosed()
          .toPromise()
          .then((x) => (window.location.search = ""));
      }
    });
  }

  openDialog(data) {
    const dialog = this.dialog.open(SuccessFailedComponent, {
      data,
      width: "500px",
    });
    return dialog;
  }

  invalidInput(attr) {
    const form = this.form;
    return (
      form.get(attr).invalid && (form.get(attr).touched || this.triedSubmitting)
    );
  }

  noPlaceSelected() {
    return !this.getPlacesSelling().trim() && this.triedSubmitting;
  }

  noDonationSelected() {
    return !this.getSelectedDonation() && this.triedSubmitting;
  }

  getPlacesSelling() {
    const checked = this.placesSelling
      .filter((p) => p.checked && p.name !== "others")
      .map((p) => p.name);
    if (this.form.value.otherPlace) {
      checked.push(this.form.value.otherPlace);
    }
    return checked.join(", ");
  }

  getAmount() {
    return this.selectedDoantion === "other"
      ? this.form.value.otherAmount
      : this.selectedDoantion;
  }

  submitForm() {
    const placesSelling = this.getPlacesSelling();
    let selectedDoantion = this.getSelectedDonation();
    this.triedSubmitting = true;
    if (this.form.valid && placesSelling && selectedDoantion) {
      const body = new HttpParams()
        .set("form-name", "chometz")

        .append(
          "subject",
          `Mechiras Chumetz Shtar for ${this.form.value.firstName} ${this.form.value.lastName}`
        )
        .append("Shtar", this.topShtar)
        .append("firstName", this.form.value.firstName)
        .append("lastName", this.form.value.lastName)
        .append("phone", this.form.value.phone)
        .append("address", this.form.value.address)
        .append("email", this.form.value.email)
        .append("comments", this.form.value.comments)
        .append("donation", this.selectedDoantion)
        .append("placesSelling", placesSelling)
        .append("consent", this.signContent)
        .append("signature", this.form.value.signature);

      this.http.post<any>("/chometz-form", body, this.options).subscribe(
        (data) => {
          this.triedSubmitting = false;
          setTimeout((x) => this.form.reset(), 2000);

          if (this.form.value.paymentType === "cc") {
            this.continueToStripe();
          } else {
            window.location.href = `https://www.paypal.com/paypalme/NewarkShul/${this.getAmount()}`;
          }
        },
        (err) => {
          console.log(err);
          if (err.status != 200) {
            this.snackbar.open(
              "There was an error. Please review the form and resubmit.",
              "dismiss",
              { duration: 3000 }
            );
          } else {
            if (this.form.value.paymentType === "cc") {
              this.continueToStripe();
            } else {
              window.location.href = `https://www.paypal.com/paypalme/NewarkShul/${this.getAmount()}`;
            }
            this.triedSubmitting = false;
            setTimeout((x) => this.form.reset(), 2000);
          }
        }
      );
    } else {
      this.snackbar.open("Please fill out all required fields", "dismiss", {
        duration: 3000,
      });
    }
  }
  getSelectedDonation() {
    return (
      this.selectedDoantion &&
      (this.selectedDoantion !== "other" || this.form.value.otherAmount > 0)
    );
  }

  onPlaceSellChange(place: Place) {
    place.checked = !place.checked;
  }

  isOtherPlace() {
    return this.placesSelling.find((p) => p.name === "others" && p.checked);
  }

  getDonationText(donation) {
    if (typeof donation === "number") {
      return "$" + donation;
    } else {
      return donation;
    }
  }

  onDonationChange(donation) {
    this.selectedDoantion = donation;
  }

  getStripeInfo(amount) {
    const url = `/.netlify/functions/stripe-process?amount=${amount}&isChometz=true`;
    return this.http.get(url);
  }

  continueToStripe() {
    this.submitting = true;

    var stripePKey =
      "pk_live_51IT5gqC40Pi3NHJDrNBjGYRHECDEzmRrknYZZwhh4Y9dGXJhFAA0Q9XBE3vv5i9OQo9DUwrotSFoOaOWVeuZr2L000ophbxf3W";
    const stripe = Stripe(stripePKey);
    const amountToCharge = this.getAmount();
    let amount = amountToCharge.toString().replace(",", "").replace("$", "");
    let decimal = "00";
    if (amount.toString().split(".")[1]) {
      amount = amount.toString().split(".")[0];
    }
    this.getStripeInfo(amount + decimal).subscribe((session: any) => {
      // console.log(session)
      this.session = session;
      stripe.redirectToCheckout({
        sessionId: this.session.id,
      });
    });
  }
}
