<header class="header">
  <ul class="header_links">
    <li class="header_link-item">
      <a class="header_link" href="#zmanim"> Zmanim </a>
    </li>
    <li class="header_link-item">
      <a class="header_link" href="#location"> Location </a>
    </li>
  </ul>
  <div [routerLink]="['/']" class="header_logo"></div>
  <ul class="header_links">
    <li class="header_link-item">
      <a class="header_link" href="#contact"> Contact </a>
    </li>
    <li class="header_link-item">
      <a class="header_link" (click)="donationDialog(0, null)"> Donate </a>
    </li>
  </ul>
</header>
