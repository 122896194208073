import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribeFormComponent } from '../subscribe-form/subscribe-form.component';

@Component({
  selector: 'app-email-banner',
  templateUrl: './email-banner.component.html',
  styleUrls: ['./email-banner.component.scss']
})
export class EmailBannerComponent implements OnInit {


  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  openForm() {
    const dialog = this.dialog.open(SubscribeFormComponent, {
      width: '400px',
      panelClass: 'cardDialog',
      autoFocus: false
    });
    dialog.afterClosed().subscribe((msg) => {
      if (msg) {
        this.snackBar.open(msg, 'Dismiss', {
          duration: 3000
        });
      }
    });
  }

}
